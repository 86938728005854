





















































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { Equipe } from "@/api/models/equipes/equipe";
import CreateEquipe from "./CreateEquipe.vue";
import UpdateEquipe from "./EditEquipe.vue";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BAlert,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    CreateEquipe,
    UpdateEquipe,
  },
})
export default class ListEquipes extends Vue {
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  isAddNewEquipeSidebarActive = false;
  isUpdateEquipeSidebarActive = false;
  selectedEquipeId = "";

  tableColumns = [{ key: "libelle", sortable: true }, { key: "nbMembre", label: "nb membre", sortable: true }, { key: "actions" }];
  paginatedListEquipes = new PaginatedList<Equipe>();

  async created() {
    await this.loadListEquipes({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListEquipes.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListEquipes.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListEquipes({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  selectEquipe(equipeId: string) {
    this.$router.push({
      name: "details-equipe",
      params: { id: equipeId },
    });
  }

  editEquipe(equipeId: string) {
    this.selectedEquipeId = equipeId;
    this.isUpdateEquipeSidebarActive = true;
  }

  async loadListEquipes(params: any) {
    if (!params || params?.reset) {
      this.paginatedListEquipes = new PaginatedList<Equipe>();
    }
    await this.$http.myressif.equipes
      .paginatedList(params?.pageNumber ?? 1, params?.pageSize ?? 10)
      .then((response: PaginatedList<Equipe>) => {
        this.paginatedListEquipes = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer cette équipe ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.equipes
      .delete(id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'une équipe",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-equipes");
        this.loadListEquipes({
          pageNumber: this.currentPage,
          pageSize: this.perPage,
        });
      });
  }
}
